var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _vm.vendorsGridShow
            ? _c(
                "v-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "10" } },
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            dense: "",
                            color: "#003d6a",
                            elevation: "0",
                            dark: "",
                          },
                        },
                        [
                          _c("v-toolbar-title", [_vm._v("Firmalar")]),
                          _c("v-spacer"),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-end",
                              staticStyle: { "min-width": "550px" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchFilterInput,
                                    expression: "searchFilterInput",
                                  },
                                ],
                                staticClass: "form-control mr-3",
                                staticStyle: { "max-width": "250px" },
                                attrs: {
                                  type: "text",
                                  autocomplete: "off",
                                  placeholder: "Arama...",
                                },
                                domProps: { value: _vm.searchFilterInput },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.onFilterTextBoxChanged()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.searchFilterInput = $event.target.value
                                  },
                                },
                              }),
                              _c("RoleProvider", {
                                attrs: { slug: "VENDOR_CREATE" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ canItPass }) {
                                        return _c(
                                          "div",
                                          {},
                                          [
                                            canItPass
                                              ? _c(
                                                  "CButton",
                                                  {
                                                    staticClass:
                                                      "py-2 px-3 font-weight-bold",
                                                    attrs: {
                                                      color: "light",
                                                      variant: "outline",
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.cleanVendorModalAddUpdateForm(),
                                                          (_vm.vendorModalAddUpdateOptions.process =
                                                            "post"),
                                                          (_vm.vendorModalAddUpdateOptions.title =
                                                            "Firma Ekle"),
                                                          (_vm.vendorModalAddUpdate = true)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        icon: ["fas", "plus"],
                                                      },
                                                    }),
                                                    _vm._v("Yeni Ekle "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3258369078
                                ),
                              }),
                              _c("RoleProvider", {
                                attrs: { slug: "VENDOR_UPDATE" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ canItPass }) {
                                        return _c(
                                          "div",
                                          {},
                                          [
                                            canItPass
                                              ? _c(
                                                  "CButton",
                                                  {
                                                    staticClass:
                                                      "py-2 px-3 ml-2 font-weight-bold",
                                                    attrs: {
                                                      color: "light",
                                                      variant: "outline",
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteCache()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Önbelleği Temizle "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3445423435
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("ag-grid-vue", {
                        staticClass: "ag-theme-balham fp-table",
                        staticStyle: {
                          width: "100%",
                          height: "calc(100vh - 145px)",
                        },
                        attrs: {
                          columnDefs: _vm.columnDefs,
                          defaultColDef: _vm.defaultColDef,
                          rowData: _vm.rowData,
                          sideBar: _vm.sideBar,
                          rowSelection: _vm.rowSelection,
                          enableRangeSelection: true,
                          enableBrowserTooltips: true,
                          statusBar: _vm.statusBar,
                        },
                        on: {
                          "grid-ready": _vm.onGridReady,
                          rowDoubleClicked: _vm.onRowDoubleClicked,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticStyle: { "z-index": "1031 !important" },
          attrs: { eager: "", "max-width": "1100" },
          model: {
            value: _vm.vendorModalAddUpdate,
            callback: function ($$v) {
              _vm.vendorModalAddUpdate = $$v
            },
            expression: "vendorModalAddUpdate",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dense: "", dark: "", color: "primary", fixed: "" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(_vm.vendorModalAddUpdateOptions.title)),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.vendorModalAddUpdate = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass: "img-vendor-container rounded",
                          attrs: { elevation: "5" },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.vendorModalAddUpdateForm.srcImage,
                              "max-height": "100",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-vendor-upload-delete-container text-center pa-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "btn-icon-center" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      id: "btnResimYukle",
                                      icon: "cloud-upload-alt",
                                      title: "Resim Yükle",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickImageUpload()
                                      },
                                    },
                                  }),
                                  _vm.vendorModalAddUpdateForm.srcImage !==
                                  _vm.ENV_URL_VENDOR + "default_vendor.png"
                                    ? _c("font-awesome-icon", {
                                        staticClass: "ml-4",
                                        attrs: {
                                          id: "btnResimSil",
                                          icon: "trash-alt",
                                          title: "Resmi Sil",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickImageDelete()
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("input", {
                                ref: "files",
                                staticClass: "files",
                                staticStyle: { display: "none" },
                                attrs: {
                                  id: "inputVendorImageFile",
                                  type: "file",
                                  custom: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.selectImage()
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-1", attrs: { cols: "9" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  label: "Firma Ünvanı",
                                },
                                model: {
                                  value: _vm.vendorModalAddUpdateForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorModalAddUpdateForm,
                                      "title",
                                      $$v
                                    )
                                  },
                                  expression: "vendorModalAddUpdateForm.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-1", attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  label: "Mersis Numarası",
                                },
                                model: {
                                  value:
                                    _vm.vendorModalAddUpdateForm.mersisNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorModalAddUpdateForm,
                                      "mersisNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorModalAddUpdateForm.mersisNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pr-1", attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  label: "Telefon",
                                },
                                model: {
                                  value: _vm.vendorModalAddUpdateForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorModalAddUpdateForm,
                                      "phone",
                                      $$v
                                    )
                                  },
                                  expression: "vendorModalAddUpdateForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-1", attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  label: "E-Posta",
                                },
                                model: {
                                  value: _vm.vendorModalAddUpdateForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorModalAddUpdateForm,
                                      "email",
                                      $$v
                                    )
                                  },
                                  expression: "vendorModalAddUpdateForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-1", attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  label: "Vergi Dairesi",
                                },
                                model: {
                                  value: _vm.vendorModalAddUpdateForm.taxOffice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorModalAddUpdateForm,
                                      "taxOffice",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorModalAddUpdateForm.taxOffice",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-1", attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "###########",
                                    expression: "'###########'",
                                  },
                                ],
                                attrs: {
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  min: "10",
                                  max: "11",
                                  label: "Vergi Numarası",
                                },
                                model: {
                                  value: _vm.vendorModalAddUpdateForm.taxNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorModalAddUpdateForm,
                                      "taxNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorModalAddUpdateForm.taxNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              "hide-details": "",
                              outlined: "",
                              "auto-grow": "",
                              dense: "",
                              label: "Adres",
                              rows: "2",
                            },
                            model: {
                              value: _vm.vendorModalAddUpdateForm.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.vendorModalAddUpdateForm,
                                  "address",
                                  $$v
                                )
                              },
                              expression: "vendorModalAddUpdateForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-4", attrs: { elevation: "10" } },
                            [
                              _c("label", [
                                _c("strong", [_vm._v("Genel Tanımlar")]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pr-1",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "Key",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .vendorKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "vendorKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.vendorKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-1",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "Marka",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm.brand,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "brand",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.brand",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-1",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "Marka Slug",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .brandSlug,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "brandSlug",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.brandSlug",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-1",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "API Durum",
                                          items:
                                            _vm.vendorModalAddUpdateFormAPIOptions,
                                          "item-text": "label",
                                          "item-value": "value",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm.api,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "api",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.api",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-1",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "Statü",
                                          items:
                                            _vm.vendorModalAddUpdateFormStatusOptions,
                                          "item-text": "label",
                                          "item-value": "value",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm.status,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.status",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-4 mt-5",
                              attrs: { elevation: "10" },
                            },
                            [
                              _c("label", [
                                _c("strong", [_vm._v("Rezervasyon Kuralları")]),
                                _c("small", { staticClass: "ml-2" }, [
                                  _vm._v(
                                    "Aşağıdaki tüm özellikler tedarikçi apisinden sağlanmalıdır."
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          "hide-details": "",
                                          label: "Normal",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .reservationTypes.normal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm
                                                .reservationTypes,
                                              "normal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.reservationTypes.normal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          "hide-details": "",
                                          label: "Full Credit",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .reservationTypes.fullCredit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm
                                                .reservationTypes,
                                              "fullCredit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      vendorModalAddUpdateForm.reservationTypes.fullCredit\n                    ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          "hide-details": "",
                                          label: "Ofiste Öde",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .reservationTypes.payOnArrival,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm
                                                .reservationTypes,
                                              "payOnArrival",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      vendorModalAddUpdateForm.reservationTypes.payOnArrival\n                    ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          "hide-details": "",
                                          label: "Parçalı Öde",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .reservationTypes.partialPayment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm
                                                .reservationTypes,
                                              "partialPayment",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      vendorModalAddUpdateForm.reservationTypes.partialPayment\n                    ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", {
                                    staticClass: "mx-3",
                                    attrs: { vertical: "" },
                                  }),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          dense: "",
                                          "hide-details": "",
                                          label: "Üzerine Satış",
                                          hint: "Partnerler gelen fiyatın üzerine koyup daha pahalıya satabilirler.",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .overSelling,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "overSelling",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.overSelling",
                                        },
                                      }),
                                      _c("small", [
                                        _vm._v(
                                          "Partnerler fiyatın üzerine kar koyup satabilirler."
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-4", attrs: { elevation: "10" } },
                            [
                              _c("label", [
                                _c("strong", [_vm._v("Firma Komisyonu (%)")]),
                                _c("small", [
                                  _vm._v(
                                    "(Brüt fiyat içindeki komisyon oranıdır.)"
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "Günlük Kiralama",
                                          type: "number",
                                          step: "0.1",
                                          min: "-100",
                                          max: "100",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .commissionRateDaily,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "commissionRateDaily",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.commissionRateDaily",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "Aylık Kiralama",
                                          type: "number",
                                          step: "0.1",
                                          min: "-100",
                                          max: "100",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .commissionRateMonthly,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "commissionRateMonthly",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.commissionRateMonthly",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-4", attrs: { elevation: "10" } },
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Fiyatı Yükselt/Düşür (%)"),
                                ]),
                                _c("small", [
                                  _vm._v(
                                    "(API fiyatı üzerine artırma/düşürme)"
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "Günlük Kiralama",
                                          type: "number",
                                          step: "0.1",
                                          min: "-100",
                                          max: "100",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .plusTotalPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "plusTotalPrice",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.plusTotalPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: "Aylık Kiralama",
                                          type: "number",
                                          step: "0.1",
                                          min: "-100",
                                          max: "100",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .plusMonthTotalPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm,
                                              "plusMonthTotalPrice",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.plusMonthTotalPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-4", attrs: { elevation: "10" } },
                            [
                              _c("label", [
                                _c("strong", [_vm._v("Kapsamlar")]),
                                _c("small", { staticClass: "ml-2" }, [
                                  _vm._v(
                                    "Aşağıdaki tüm özellikler firmanın tüm araçları için internet sitesi üzerinde listelenecektir."
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.kapsam,
                                      "item-text": (item) => item.tr,
                                      "item-value": "val",
                                      "hide-details": "",
                                      chips: "",
                                      dense: "",
                                      "small-chips": "",
                                      multiple: "",
                                    },
                                    model: {
                                      value:
                                        _vm.vendorModalAddUpdateForm.inclusives,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.vendorModalAddUpdateForm,
                                          "inclusives",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "vendorModalAddUpdateForm.inclusives",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("hr"),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "10" } },
                            [
                              _c("label", { staticClass: "pl-3 pt-4" }, [
                                _c("strong", [_vm._v("Yetkili Kişiler")]),
                              ]),
                              _vm._l(
                                _vm.vendorModalAddUpdateForm.contacts,
                                function (contact, i) {
                                  return _c(
                                    "v-row",
                                    { key: i },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pr-1",
                                          attrs: { cols: "11" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pr-1",
                                                  attrs: { cols: "2" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": "",
                                                      dense: "",
                                                      placeholder: "Unvan",
                                                    },
                                                    model: {
                                                      value: contact.title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          contact,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.title",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "px-1",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": "",
                                                      dense: "",
                                                      placeholder: "Ad Soyad",
                                                    },
                                                    model: {
                                                      value: contact.fullname,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          contact,
                                                          "fullname",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.fullname",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "px-1",
                                                  attrs: { cols: "2" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": "",
                                                      dense: "",
                                                      placeholder: "Telefon",
                                                    },
                                                    model: {
                                                      value: contact.phone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          contact,
                                                          "phone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.phone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pl-1",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": "",
                                                      dense: "",
                                                      placeholder: "E-Posta",
                                                    },
                                                    model: {
                                                      value: contact.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          contact,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.email",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-3 pl-1",
                                          attrs: { cols: "1" },
                                        },
                                        [
                                          _vm.vendorModalAddUpdateForm.contacts
                                            .length -
                                            1 ==
                                          i
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center justify-content-center",
                                                  staticStyle: {
                                                    height: "100%",
                                                    cursor: "pointer",
                                                    "background-color": "#eee",
                                                    "border-radius": "5px",
                                                  },
                                                  on: {
                                                    click: _vm.contactsAdd,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: { icon: "plus" },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center justify-content-center",
                                                  staticStyle: {
                                                    height: "100%",
                                                    cursor: "pointer",
                                                    "background-color": "#fff",
                                                    "border-radius": "5px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.contactsDelete(
                                                        contact
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: "trash-alt",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-3", attrs: { elevation: "10" } },
                            [
                              _c("label", [
                                _c("strong", [_vm._v("Firma Ciro")]),
                              ]),
                              _c(
                                "v-row",
                                { staticClass: "justify-content-between" },
                                [
                                  _vm.vendorModalAddUpdateOptions.process ==
                                  "put"
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass: "w-40 p-3",
                                          attrs: { elevation: "0" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              label: "Rezervasyon Toplamı",
                                              disabled: true,
                                              value:
                                                _vm.vendorModalAddUpdateForm
                                                  ?.tracking?.reservationTotal,
                                            },
                                          }),
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              disabled: true,
                                              label:
                                                "İptal Rezervasyon Toplamı",
                                              value:
                                                _vm.vendorModalAddUpdateForm
                                                  ?.tracking
                                                  ?.reservaitonTotalCancel,
                                            },
                                          }),
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              disabled: true,
                                              label: "Kalan Limit",
                                              value:
                                                _vm.vendorModalAddUpdateForm
                                                  ?.tracking?.remainingLimit,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "w-50 p-3",
                                      style:
                                        _vm.vendorModalAddUpdateOptions
                                          .process == "put"
                                          ? "width:100%"
                                          : "",
                                      attrs: { elevation: "0" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          placeholder: "Limit",
                                          label: "Limit",
                                          type: "number",
                                          step: "0.1",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .tracking.limit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm
                                                .tracking,
                                              "limit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.tracking.limit",
                                        },
                                      }),
                                      _c("v-textarea", {
                                        attrs: {
                                          "auto-grow": "",
                                          rows: "4",
                                          dense: "",
                                          placeholder: "Not",
                                          label: "Not",
                                        },
                                        model: {
                                          value:
                                            _vm.vendorModalAddUpdateForm
                                              .tracking.note,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vendorModalAddUpdateForm
                                                .tracking,
                                              "note",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vendorModalAddUpdateForm.tracking.note",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("RoleProvider", {
                    attrs: { slug: "VENDOR_DELETE" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ canItPass }) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.vendorModalAddUpdateOptions.process ===
                                "put" && canItPass
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-4",
                                      attrs: { color: "error" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.vendorDelete(
                                            _vm.vendorModalAddUpdateForm._id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("SİL")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("RoleProvider", {
                    attrs: { slug: "VENDOR_CREATE" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ canItPass }) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.vendorModalAddUpdateOptions.process ===
                                "post" && canItPass
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "success",
                                        disabled:
                                          _vm.vendorModalAddUpdateBtnDisable,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.vendorAddUpdate()
                                        },
                                      },
                                    },
                                    [_vm._v("EKLE")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("RoleProvider", {
                    attrs: { slug: "VENDOR_UPDATE" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ canItPass }) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.vendorModalAddUpdateOptions.process ===
                                "put" && canItPass
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "success",
                                        disabled:
                                          _vm.vendorModalAddUpdateBtnDisable,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.vendorAddUpdate()
                                        },
                                      },
                                    },
                                    [_vm._v("DÜZENLE")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }