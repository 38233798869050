<template>
  <div>
    <v-row>
      <v-col md="12" v-if="vendorsGridShow">
        <v-card elevation="10">
          <v-toolbar dense color="#003d6a" elevation="0" dark>
            <v-toolbar-title>Firmalar</v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                autocomplete="off"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
              <RoleProvider slug="VENDOR_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    v-if="canItPass"
                    @click="
                      cleanVendorModalAddUpdateForm(),
                        (vendorModalAddUpdateOptions.process = 'post'),
                        (vendorModalAddUpdateOptions.title = 'Firma Ekle'),
                        (vendorModalAddUpdate = true)
                    "
                    class="py-2 px-3 font-weight-bold"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
              <RoleProvider slug="VENDOR_UPDATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    v-if="canItPass"
                    @click="deleteCache()"
                    class="py-2 px-3 ml-2 font-weight-bold"
                  >
                    Önbelleği Temizle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <!-- :sendToClipboard="sendToClipboard" -->
          <ag-grid-vue
            style="width: 100%; height: calc(100vh - 145px)"
            class="ag-theme-balham fp-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="rowData"
            :sideBar="sideBar"
            :rowSelection="rowSelection"
            :enableRangeSelection="true"
            :enableBrowserTooltips="true"
            :statusBar="statusBar"
            @grid-ready="onGridReady"
            @rowDoubleClicked="onRowDoubleClicked"
          ></ag-grid-vue>

          <!-- :getContextMenuItems="getGridContextMenuItems" -->
        </v-card>
      </v-col>
    </v-row>
    <!-- :closeOnBackdrop="false" -->
    <v-dialog
      style="z-index: 1031 !important"
      v-model="vendorModalAddUpdate"
      eager
      max-width="1100"
    >
      <v-card>
        <v-toolbar dense dark color="primary" fixed>
          <v-toolbar-title>{{
            vendorModalAddUpdateOptions.title
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="vendorModalAddUpdate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="3">
            <v-sheet class="img-vendor-container rounded" elevation="5">
              <v-img
                :src="vendorModalAddUpdateForm.srcImage"
                max-height="100"
              />
              <div class="img-vendor-upload-delete-container text-center pa-2">
                <div class="btn-icon-center">
                  <font-awesome-icon
                    id="btnResimYukle"
                    icon="cloud-upload-alt"
                    title="Resim Yükle"
                    @click="clickImageUpload()"
                  />
                  <font-awesome-icon
                    id="btnResimSil"
                    icon="trash-alt"
                    class="ml-4"
                    title="Resmi Sil"
                    v-if="
                      vendorModalAddUpdateForm.srcImage !==
                      ENV_URL_VENDOR + 'default_vendor.png'
                    "
                    @click="clickImageDelete()"
                  />
                </div>
                <input
                  id="inputVendorImageFile"
                  type="file"
                  class="files"
                  ref="files"
                  style="display: none"
                  custom
                  v-on:change="selectImage()"
                />
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="9" class="pr-1">
                <v-text-field
                  hide-details=""
                  outlined
                  dense
                  label="Firma Ünvanı"
                  v-model="vendorModalAddUpdateForm.title"
                />
              </v-col>
              <v-col cols="3" class="pl-1">
                <v-text-field
                  hide-details=""
                  outlined
                  dense
                  label="Mersis Numarası"
                  v-model="vendorModalAddUpdateForm.mersisNumber"
                />
              </v-col>

              <v-col cols="3" class="pr-1">
                <v-text-field
                  hide-details=""
                  outlined
                  dense
                  label="Telefon"
                  v-model="vendorModalAddUpdateForm.phone"
                />
              </v-col>
              <v-col cols="3" class="px-1">
                <v-text-field
                  hide-details=""
                  outlined
                  dense
                  label="E-Posta"
                  v-model="vendorModalAddUpdateForm.email"
                />
              </v-col>

              <v-col cols="3" class="px-1">
                <v-text-field
                  hide-details=""
                  outlined
                  dense
                  label="Vergi Dairesi"
                  v-model="vendorModalAddUpdateForm.taxOffice"
                />
              </v-col>
              <v-col cols="3" class="pl-1">
                <v-text-field
                  hide-details=""
                  outlined
                  dense
                  min="10"
                  max="11"
                  v-mask="'###########'"
                  label="Vergi Numarası"
                  v-model="vendorModalAddUpdateForm.taxNumber"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-row>
            <v-col cols="12">
              <v-textarea
                hide-details=""
                outlined
                auto-grow
                dense
                label="Adres"
                rows="2"
                v-model="vendorModalAddUpdateForm.address"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card elevation="10" class="pa-4">
                <label><strong>Genel Tanımlar</strong></label>
                <v-row>
                  <v-col cols="2" class="pr-1">
                    <v-text-field
                      hide-details=""
                      dense
                      label="Key"
                      v-model="vendorModalAddUpdateForm.vendorKey"
                    />
                  </v-col>
                  <v-col cols="2" class="px-1">
                    <v-text-field
                      hide-details=""
                      dense
                      label="Marka"
                      v-model="vendorModalAddUpdateForm.brand"
                    />
                  </v-col>
                  <v-col cols="2" class="px-1">
                    <v-text-field
                      hide-details=""
                      dense
                      label="Marka Slug"
                      v-model="vendorModalAddUpdateForm.brandSlug"
                    />
                  </v-col>
                  <v-col cols="4" class="px-1">
                    <v-select
                      hide-details=""
                      dense
                      label="API Durum"
                      v-model="vendorModalAddUpdateForm.api"
                      :items="vendorModalAddUpdateFormAPIOptions"
                      item-text="label"
                      item-value="value"
                    />
                  </v-col>
                  <v-col cols="2" class="pl-1">
                    <v-select
                      hide-details=""
                      dense
                      label="Statü"
                      v-model="vendorModalAddUpdateForm.status"
                      :items="vendorModalAddUpdateFormStatusOptions"
                      item-text="label"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card elevation="10" class="pa-4 mt-5">
                <label
                  ><strong>Rezervasyon Kuralları</strong>
                  <small class="ml-2"
                    >Aşağıdaki tüm özellikler tedarikçi apisinden
                    sağlanmalıdır.</small
                  ></label
                >
                <v-row>
                  <v-col cols="2">
                    <v-switch
                      hide-details=""
                      label="Normal"
                      v-model="vendorModalAddUpdateForm.reservationTypes.normal"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      hide-details=""
                      label="Full Credit"
                      v-model="
                        vendorModalAddUpdateForm.reservationTypes.fullCredit
                      "
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      hide-details=""
                      label="Ofiste Öde"
                      v-model="
                        vendorModalAddUpdateForm.reservationTypes.payOnArrival
                      "
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      hide-details=""
                      label="Parçalı Öde"
                      v-model="
                        vendorModalAddUpdateForm.reservationTypes.partialPayment
                      "
                    />
                  </v-col>
                  <v-divider vertical class="mx-3" />
                  <v-col cols="3">
                    <v-checkbox
                      dense
                      hide-details=""
                      label="Üzerine Satış"
                      hint="Partnerler gelen fiyatın üzerine koyup daha pahalıya satabilirler."
                      v-model="vendorModalAddUpdateForm.overSelling"
                    />
                    <small
                      >Partnerler fiyatın üzerine kar koyup satabilirler.</small
                    >
                  </v-col>
                </v-row>
              </v-card>
             
            </v-col>

            <v-col cols="6">
              <v-card elevation="10" class="pa-4">
                <label
                  ><strong>Firma Komisyonu (%)</strong>
                  <small>(Brüt fiyat içindeki komisyon oranıdır.)</small></label
                >
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      hide-details=""
                      dense
                      label="Günlük Kiralama"
                      type="number"
                      step="0.1"
                      min="-100"
                      max="100"
                      v-model="vendorModalAddUpdateForm.commissionRateDaily"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      hide-details=""
                      dense
                      label="Aylık Kiralama"
                      type="number"
                      step="0.1"
                      min="-100"
                      max="100"
                      v-model="vendorModalAddUpdateForm.commissionRateMonthly"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

          

            <v-col cols="6">
              <v-card elevation="10" class="pa-4">
                <label
                  ><strong>Fiyatı Yükselt/Düşür (%)</strong>
                  <small>(API fiyatı üzerine artırma/düşürme)</small></label
                >
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      hide-details=""
                      dense
                      label="Günlük Kiralama"
                      type="number"
                      step="0.1"
                      min="-100"
                      max="100"
                      v-model="vendorModalAddUpdateForm.plusTotalPrice"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      hide-details=""
                      dense
                      label="Aylık Kiralama"
                      type="number"
                      step="0.1"
                      min="-100"
                      max="100"
                      v-model="vendorModalAddUpdateForm.plusMonthTotalPrice"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card elevation="10" class="pa-4">
                <label
                  ><strong>Kapsamlar</strong> 
                  <small class="ml-2"
                    >Aşağıdaki tüm özellikler firmanın tüm araçları için
                    internet sitesi üzerinde listelenecektir.</small
                  ></label
                >
                <v-col cols="12">
                  <v-autocomplete
                    v-model="vendorModalAddUpdateForm.inclusives"
                    :items="kapsam"
                    :item-text="(item) => item.tr"
                    item-value="val"
                    hide-details
                    chips
                    dense
                    small-chips
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-card>
            </v-col>
          </v-row>

          <hr />

          <v-row>
            <v-col cols="12">
              <v-card elevation="10">
                <label class="pl-3 pt-4"
                  ><strong>Yetkili Kişiler</strong></label
                >
                <v-row
                  v-for="(contact, i) in vendorModalAddUpdateForm.contacts"
                  :key="i"
                >
                  <v-col cols="11" class="pr-1">
                    <v-row>
                      <v-col cols="2" class="pr-1">
                        <v-text-field
                          hide-details=""
                          dense
                          placeholder="Unvan"
                          v-model="contact.title"
                        />
                      </v-col>
                      <v-col cols="4" class="px-1">
                        <v-text-field
                          hide-details=""
                          dense
                          placeholder="Ad Soyad"
                          v-model="contact.fullname"
                        />
                      </v-col>
                      <v-col cols="2" class="px-1">
                        <v-text-field
                          hide-details=""
                          dense
                          placeholder="Telefon"
                          v-model="contact.phone"
                        />
                      </v-col>
                      <v-col cols="4" class="pl-1">
                        <v-text-field
                          hide-details=""
                          dense
                          placeholder="E-Posta"
                          v-model="contact.email"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="mb-3 pl-1" cols="1">
                    <div
                      class="d-flex align-items-center justify-content-center"
                      style="
                        height: 100%;
                        cursor: pointer;
                        background-color: #eee;
                        border-radius: 5px;
                      "
                      v-if="vendorModalAddUpdateForm.contacts.length - 1 == i"
                      @click="contactsAdd"
                    >
                      <font-awesome-icon icon="plus" />
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-center justify-content-center"
                      style="
                        height: 100%;
                        cursor: pointer;
                        background-color: #fff;
                        border-radius: 5px;
                      "
                      @click="contactsDelete(contact)"
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <hr />
            <v-col cols="12">
              <v-card elevation="10" class="pa-3">
                <label><strong>Firma Ciro</strong></label>
                <v-row class="justify-content-between">
                  <v-card
                    elevation="0"
                    v-if="vendorModalAddUpdateOptions.process == 'put'"
                    class="w-40 p-3"
                  >
                    <v-text-field
                      dense
                      label="Rezervasyon Toplamı"
                      :disabled="true"
                      :value="
                        vendorModalAddUpdateForm?.tracking?.reservationTotal
                      "
                    />
                    <v-text-field
                      dense
                      :disabled="true"
                      label="İptal Rezervasyon Toplamı"
                      :value="
                        vendorModalAddUpdateForm?.tracking
                          ?.reservaitonTotalCancel
                      "
                    />
                    <v-text-field
                      dense
                      :disabled="true"
                      label="Kalan Limit"
                      :value="
                        vendorModalAddUpdateForm?.tracking?.remainingLimit
                      "
                    />
                  </v-card>
                  <v-card
                    elevation="0"
                    class="w-50 p-3"
                    :style="
                      vendorModalAddUpdateOptions.process == 'put'
                        ? 'width:100%'
                        : ''
                    "
                  >
                    <v-text-field
                      dense
                      placeholder="Limit"
                      label="Limit"
                      type="number"
                      step="0.1"
                      v-model="vendorModalAddUpdateForm.tracking.limit"
                    />
                    <v-textarea
                      auto-grow
                      rows="4"
                      dense
                      placeholder="Not"
                      label="Not"
                      v-model="vendorModalAddUpdateForm.tracking.note"
                    />
                  </v-card>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <RoleProvider slug="VENDOR_DELETE">
            <div slot-scope="{ canItPass }">
              <v-btn
                v-if="
                  vendorModalAddUpdateOptions.process === 'put' && canItPass
                "
                color="error"
                @click="vendorDelete(vendorModalAddUpdateForm._id)"
                class="mr-4"
                >SİL</v-btn
              >
            </div>
          </RoleProvider>
          <RoleProvider slug="VENDOR_CREATE">
            <div slot-scope="{ canItPass }">
              <v-btn
                v-if="
                  vendorModalAddUpdateOptions.process === 'post' && canItPass
                "
                color="success"
                :disabled="vendorModalAddUpdateBtnDisable"
                @click="vendorAddUpdate()"
                >EKLE</v-btn
              >
            </div>
          </RoleProvider>
          <RoleProvider slug="VENDOR_UPDATE">
            <div slot-scope="{ canItPass }">
              <v-btn
                v-if="
                  vendorModalAddUpdateOptions.process === 'put' && canItPass
                "
                color="success"
                :disabled="vendorModalAddUpdateBtnDisable"
                @click="vendorAddUpdate()"
                >DÜZENLE</v-btn
              >
            </div>
          </RoleProvider>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import { RoleProvider } from "../../provider";
import { IMaskComponent } from "vue-imask";
import moment from "moment";
var _ = require("lodash");
axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

export default {
  name: "VendorSearch",
  data() {
    return {
      styleObject: {
        display: "flex",
        alignItems: "center",
      },
      searchFilterInput: "",
      ENV_URL_VENDOR: process.env.VUE_APP_CDN_URL_VENDOR,
      defaultVendorImage: "default_vendor.png",
      // AG GRID
      vendorsGridShow: false,
      columnDefs: [
        {
          field: "status",
          cellRenderer: (params) => {
            return params.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value
              ? {
                  color: "#2eb85c",
                  fontWeight: "bold",
                }
              : {
                  color: "#e55353",
                  fontWeight: "bold",
                };
          },
          headerName: "Durum",
          width: 100,
        },

        {
          field: "brand",
          headerName: "Marka",
          width: 150,
        },

        {
          field: "title",
          headerName: "Ünvan",
          width: 400,
        },
        {
          field: "reservationTypes.normal",
          headerName: "Normal Rez",
          width: 150,
          cellRenderer: (params) => (params.value ? "Aktif" : ""),
          cellStyle: (params) =>
            params.value ? { color: "white", backgroundColor: "green" } : {},
        },
        {
          field: "reservationTypes.fullCredit",
          headerName: "Full Credit Rezarvasyon",
          width: 150,
          cellRenderer: (params) => (params.value ? "Aktif" : ""),
          cellStyle: (params) =>
            params.value ? { color: "white", backgroundColor: "green" } : {},
        },
        {
          field: "reservationTypes.payOnArrival",
          headerName: "Ofiste Öde Rez",
          width: 150,
          cellRenderer: (params) => (params.value ? "Aktif" : ""),
          cellStyle: (params) =>
            params.value ? { color: "white", backgroundColor: "green" } : {},
        },
        {
          field: "reservationTypes.partialPayment",
          headerName: "Parçalı Öde Rez",
          width: 150,
          cellRenderer: (params) => (params.value ? "Aktif" : ""),
          cellStyle: (params) =>
            params.value ? { color: "white", backgroundColor: "green" } : {},
        },
        {
          field: "phone",
          headerName: "Telefon",
          width: 200,
        },
        {
          field: "email",
          headerName: "E-Posta",
        },
        {
          field: "address",
          headerName: "Adres",
          width: 500,
        },

        {
          field: "taxOffice",
          headerName: "Vergi Dairesi",
        },
        {
          field: "taxNumber",
          headerName: "Vergi Numarası",
        },
        {
          field: "mersisNumber",
          headerName: "Mersis Numarası",
        },

        // {
        //   field: "findeks",
        //   headerName: "Findeks Kontrolü",
        //   width: 150,
        //   cellRenderer: (params) => {
        //     return params.value ? "Kontrol Ediliyor" : "Kontrol Edilmiyor";
        //   },
        //   cellStyle: (params) => {
        //     return params.value
        //       ? { color: "#2eb85c", fontWeight: "bold" }
        //       : { color: "#e55353", fontWeight: "bold" };
        //   },
        // },
        // {
        //   field: "findeksNote",
        //   headerName: "Findeks Notu (Min)",
        //   width: 160,
        // },
        {
          field: "api",
          cellRenderer: (params) => {
            if (params.value === 0) return "Yok";
            else if (params.value === 1) return "Aktif";
            else if (params.value === 2) return "Entegrasyon Aşamasında";
          },
          cellStyle: (params) => {
            if (params.value === 0)
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
            else if (params.value === 1)
              return {
                color: "#2eb85c",
                fontWeight: "bold",
              };
            else if (params.value === 2)
              return {
                color: "#f9b115",
                fontWeight: "bold",
              };
          },
          headerName: "API",
          width: 200,
        },
        {
          field: "createdAt",
          headerName: "Oluşturma Tarihi",
          cellRenderer: (params) => {
            if (!params.value) return "Bulunamadı";
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bil eşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      // FİRMA ARAMA
      searchVendorFormCollapsed: false,
      searchVendorFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchVendorFormAPIOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: 0,
          label: "Yok",
        },
        {
          value: 1,
          label: "Aktif",
        },
        {
          value: 2,
          label: "Entegrasyon Aşamasında",
        },
      ],
      searchVendorForm: {
        status: "",
        brand: "",
        api: "",
      },

      kapsam: [
        {
          val: "damage_exemption",
          en: "Damage Exemption",
          tr: "Hasar Güvencesi",
        },
        {
          val: "theft_protection",
          en: "Theft Production",
          tr: "Hırsızlık Koruması",
        },
        {
          val: "third_party_Coverage",
          en: "Third Party Coverage",
          tr: "Üçüncü Taraf Kapsamı",
        },
        {
          val: "unlimited_mileage",
          en: "Unlimited Mileage",
          tr: "Sınırsız Kilometre",
        },
        {
          val: "free_cancellation",
          en: "Free Cancellation",
          tr: "Ücretsiz İptal",
        },
        {
          val: "free_damage_assistance",
          en: "Free Damage Assistance",
          tr: "Ücretsiz Hasar Yardımı",
        },
      ],

      // FİRMA EKLEME GÜNCELLEME MODAL
      vendorModalAddUpdate: false, // Vendor Modal Add Update
      vendorModalAddUpdateBtnDisable: false, // Vendor Modal Add Update button disabled enabled değişkeni
      vendorModalAddUpdateOptions: {
        // Vendor Modal Add Update değişkenleri
        process: "",
        title: "",
        shake: {
          title: false,
          brand: false,
          brandSlug: false,
          findeksNote: false,
        },
      },
      vendorModalAddUpdateFormStatusOptions: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      fullCreditOptions: [
        {
          value: false,
          label: "Yok",
        },
        {
          value: true,
          label: "Var",
        },
      ],
      vendorModalAddUpdateFormAPIOptions: [
        {
          value: 0,
          label: "Yok",
        },
        {
          value: 1,
          label: "Aktif",
        },
        {
          value: 2,
          label: "Entegrasyon Aşamasında",
        },
      ],
      vendorModalAddUpdateFormFindeksOptions: [
        {
          value: false,
          label: "Kontrol Edilmesin",
        },
        {
          value: true,
          label: "Kontrol Edilsin",
        },
      ],

      vendorModalAddUpdateFormPlusTotalPriceOptions: [
        {
          value: false,
          label: "Yok",
        },
        {
          value: true,
          label: "Var",
        },
      ],
      plusTotalPriceStatus: false,
      vendorModalAddUpdateForm: {
        _id: "",
        srcImage: "",
        files: null,
        title: "",
        brand: "",
        brandSlug: "",
        status: false,
        api: 0,
        findeks: false,
        findeksNote: 0,
        plusTotalPrice: 0,
        plusMonthTotalPrice: 0,
        commissionRateDaily: 0,
        commissionRateMonthly: 0,
        isFullCredit: false,
        reservationTypes: {
          normal: false,
          fullCredit: false,
          payOnArrival: false,
          partialPayment: false,
        },
        overSelling: false,
        isNetPrice: true,
        rate: 0,
        address: "",
        vendorKey: "",
        phone: "",
        inclusives: [],
        mersisNumber: "",
        taxOffice: "",
        taxNumber: "",
        email: "",
        tracking: {
          limit: 0,
          note: "",
        },
        contacts: [
          {
            fullname: "",
            title: "",
            phone: "",
            email: "",
          },
        ],
      },
    };
  },
  components: {
    AgGridVue,
    RoleProvider,
  },
  created: function () {},
  mounted: function () {
    this.searchVendor();
    this.lastFilter();
  },
  methods: {
    lastFilter() {
      this.searchFilterInput =
        JSON.parse(localStorage.getItem("VendorslastFilter") || "{}")
          .VendorsPageFilter || "";
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
      localStorage.setItem(
        "VendorslastFilter",
        JSON.stringify({ VendorsPageFilter: this.searchFilterInput })
      );
    },
    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    contactsAdd() {
      var contact = {
        fullname: "",
        title: "",
        phone: "",
        email: "",
      };
      this.vendorModalAddUpdateForm.contacts.push(contact);
    },

    contactsDelete(val) {
      Vue.swal
        .fire({
          icon: "question",
          text: "Yetkili kişiyi silmek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var item = this.vendorModalAddUpdateForm.contacts.indexOf(val);

            this.vendorModalAddUpdateForm.contacts.splice(item, 1);
          }
        });
    },

    deleteCache() {
      Vue.swal
        .fire({
          icon: "question",
          text: "Önbelleği temizlemek istiyor musunuz? (Bu işlem firma araç ve lokasyon eşleştirmeleri bittikten sonra bir kere yapılmalıdır.)",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            axios
              .post(process.env.VUE_APP_API_URL + "admin/vendor/cache")
              .then((res) => {
                if (res.data.result == "success") {
                  Vue.swal.fire({
                    icon: "success",
                    text: "Önbellek temizlendi.",
                    showCloseButton: true,
                  });
                } else {
                  Vue.swal.fire({
                    icon: "error",
                    text: "Önbellek temizlenemedi.",
                    showCloseButton: true,
                  });
                }
              });
          }
        });
    },

    cleanVendorModalAddUpdateForm() {
      this.vendorModalAddUpdateForm._id = "";
      this.vendorModalAddUpdateForm.srcImage = "";
      this.vendorModalAddUpdateForm.files = null;
      this.vendorModalAddUpdateForm.title = "";
      this.vendorModalAddUpdateForm.brand = "";
      this.vendorModalAddUpdateForm.brandSlug = "";
      this.vendorModalAddUpdateForm.vendorKey = "";

      this.vendorModalAddUpdateForm.status =
        this.vendorModalAddUpdateFormStatusOptions[0].value;
      this.vendorModalAddUpdateForm.api =
        this.vendorModalAddUpdateFormAPIOptions[0].value;
      this.vendorModalAddUpdateForm.findeks = false;
      this.vendorModalAddUpdateForm.address = "";
      this.vendorModalAddUpdateForm.phone = "";
      this.vendorModalAddUpdateForm.email = "";
      (this.vendorModalAddUpdateForm.inclusives = []),
        (this.vendorModalAddUpdateForm.taxOffice = "");
      this.vendorModalAddUpdateForm.taxNumber = "";
      this.vendorModalAddUpdateForm.mersisNumber = "";
      this.vendorModalAddUpdateForm.rate = 0;
      this.vendorModalAddUpdateForm.isFullCredit = false;
      (this.vendorModalAddUpdateForm.reservationTypes = {
        normal: false,
        fullCredit: false,
        payOnArrival: false,
        partialPayment: false,
      }),
        (this.vendorModalAddUpdateForm.contacts = [
          {
            fullname: "",
            title: "",
            phone: "",
            email: "",
          },
        ]);
      this.vendorModalAddUpdateForm.overSelling = false;
      this.vendorModalAddUpdateForm.findeksNote = 0;
      this.vendorModalAddUpdateForm.plusTotalPrice = 0;
      this.vendorModalAddUpdateForm.plusMonthTotalPrice = 0;
      this.vendorModalAddUpdateForm.commissionRateDaily = 0;
      this.vendorModalAddUpdateForm.commissionRateMonthly = 0;
      this.vendorModalAddUpdateForm.tracking = { limit: 0, note: "" };

      document.getElementById("inputVendorImageFile").value = "";
    },

    clickImageUpload() {
      document.getElementById("inputVendorImageFile").click();
    },

    clickImageDelete() {
      this.vendorModalAddUpdateForm.srcImage =
        this.ENV_URL_VENDOR + this.defaultVendorImage;
      this.vendorModalAddUpdateForm.files = null;
      document.getElementById("inputVendorImageFile").value = "";
    },

    selectImage() {
      const fileImage = this.$refs.files.files[0];

      if (fileImage.size > 1048576) {
        Vue.swal({
          icon: "error",
          title: "Firma Ekle - Resim",
          html: "Maksimum 1 MB resim yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      } else {
        if (
          fileImage.type === "image/png" ||
          fileImage.type === "image/jpg" ||
          fileImage.type === "image/jpeg"
        ) {
          let reader = new FileReader();

          reader.onload = (e) => {
            this.vendorModalAddUpdateForm.srcImage = e.target.result;
            this.vendorModalAddUpdateForm.files = fileImage;
          };
          reader.readAsDataURL(fileImage);
        } else {
          Vue.swal({
            icon: "error",
            title: "Firma Ekle - Resim",
            html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
            confirmButtonText: "Tamam",
          });
        }
      }
    },

    searchVendor() {
      this.$Progress.start();

      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor", {
          params: this.searchVendorForm,
        })
        .then((response) => {
          this.$Progress.finish();
          this.rowData = _.reverse(response.data.vendorList);
          this.searchVendorFormCollapsed = false;
          this.vendorsGridShow = true;
        });
    },

    vendorAddUpdate() {
      this.$Progress.start();

      this.vendorModalAddUpdateBtnDisable = true; // Modal'daki post button'u disable ettik.

      var formData = new FormData();

      if (this.vendorModalAddUpdateOptions.process === "put")
        formData.append("_id", this.vendorModalAddUpdateForm._id);

      if (
        this.vendorModalAddUpdateForm.files !== null &&
        this.vendorModalAddUpdateForm.files.size > 0
      )
        formData.append("image", this.vendorModalAddUpdateForm.files);
      else if (
        this.vendorModalAddUpdateForm.srcImage ===
        this.ENV_URL_VENDOR + this.defaultVendorImage
      )
        formData.append("image", this.defaultVendorImage);
      formData.append("title", this.vendorModalAddUpdateForm.title);
      formData.append("brand", this.vendorModalAddUpdateForm.brand);
      formData.append("brandSlug", this.vendorModalAddUpdateForm.brandSlug);
      formData.append("status", this.vendorModalAddUpdateForm.status);
      formData.append("api", this.vendorModalAddUpdateForm.api);
      formData.append("findeks", this.vendorModalAddUpdateForm.findeks);
      formData.append(
        "plusTotalPrice",
        this.vendorModalAddUpdateForm.plusTotalPrice
      );
      formData.append(
        "plusMonthTotalPrice",
        this.vendorModalAddUpdateForm.plusMonthTotalPrice
      );
      formData.append(
        "commissionRateDaily",
        this.vendorModalAddUpdateForm.commissionRateDaily
      );
      formData.append(
        "commissionRateMonthly",
        this.vendorModalAddUpdateForm.commissionRateMonthly
      );
      formData.append("isNetPrice", this.vendorModalAddUpdateForm.isNetPrice);
      formData.append(
        "isFullCredit",
        this.vendorModalAddUpdateForm.isFullCredit
      );
      formData.append(
        "reservationTypes",
        JSON.stringify(this.vendorModalAddUpdateForm.reservationTypes)
      );
      formData.append("overSelling", this.vendorModalAddUpdateForm.overSelling);
      formData.append("phone", this.vendorModalAddUpdateForm.phone);
      formData.append(
        "inclusives",
        this.vendorModalAddUpdateForm?.inclusives?.length>0 && JSON.stringify(this.vendorModalAddUpdateForm.inclusives)
      );
      formData.append("address", this.vendorModalAddUpdateForm.address);
      formData.append("email", this.vendorModalAddUpdateForm.email);
      formData.append("taxOffice", this.vendorModalAddUpdateForm.taxOffice);
      formData.append("taxNumber", this.vendorModalAddUpdateForm.taxNumber);
      formData.append("vendorKey", this.vendorModalAddUpdateForm.vendorKey);
      formData.append(
        "contacts",
        JSON.stringify(this.vendorModalAddUpdateForm.contacts)
      );

      formData.append(
        "mersisNumber",
        this.vendorModalAddUpdateForm.mersisNumber
      );
      formData.append(
        "findeksNote",
        this.vendorModalAddUpdateForm.findeks
          ? this.vendorModalAddUpdateForm.findeksNote
          : 0
      );
      formData.append(
        "tracking",
        JSON.stringify({
          limit: Number(this.vendorModalAddUpdateForm.tracking.limit),
          note: this.vendorModalAddUpdateForm.tracking.note,
        })
      );

      axios[this.vendorModalAddUpdateOptions.process](
        process.env.VUE_APP_API_URL + "admin/vendor",
        formData,
        {
          headers: {
            "Content-Type": false,
          },
        }
      ).then((response) => {
        this.$Progress.finish();
        this.vendorModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

        if (response.data.result === "success") {
          if (this.vendorsGridShow) this.searchVendor(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
          this.vendorModalAddUpdate = false; // Modal'ı kapattık
        }

        Vue.swal({
          icon: response.data.result,
          title:
            "Firma " + this.vendorModalAddUpdateOptions.process === "post"
              ? "Ekle"
              : "Düzenle",
          html: response.data.message,
          confirmButtonText: "Tamam",
        });
      });
    },

    vendorDelete(id) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu firmayı silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            try {
              axios.delete(process.env.VUE_APP_API_URL + "admin/vendor/" + id, {
                withCredentials: true,
              });
              this.$Progress.finish();

              this.$store.dispatch({
                type: "successSwal",
                text: "Firma başarılı bir şekilde silindi",
                refresh: true,
              });
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },

    onRowDoubleClicked(params) {
      this.cleanVendorModalAddUpdateForm();
      this.vendorModalAddUpdateOptions.process = "put";
      this.vendorModalAddUpdateOptions.title = "Firma Güncelle";

      this.vendorModalAddUpdateForm._id = params.node.data._id;
      this.vendorModalAddUpdateForm.srcImage =
        this.ENV_URL_VENDOR + params.node.data.image;
      this.vendorModalAddUpdateForm.title = params.node.data.title;
      this.vendorModalAddUpdateForm.brand = params.node.data.brand;
      this.vendorModalAddUpdateForm.brandSlug = params.node.data.brandSlug;
      this.vendorModalAddUpdateForm.vendorKey = params.node.data.vendorKey;

      this.vendorModalAddUpdateForm.status = params.node.data.status;
      this.vendorModalAddUpdateForm.api = params.node.data.api;
      this.vendorModalAddUpdateForm.findeks = params.node.data.findeks;
      this.vendorModalAddUpdateForm.plusTotalPrice =
        params.node.data.plusTotalPrice;
      this.vendorModalAddUpdateForm.plusMonthTotalPrice =
        params.node.data.plusMonthTotalPrice;
      this.vendorModalAddUpdateForm.commissionRateDaily =
        params.node.data.commissionRateDaily;
      this.vendorModalAddUpdateForm.commissionRateMonthly =
        params.node.data.commissionRateMonthly;
      this.vendorModalAddUpdateForm.isFullCredit =
        params.node.data.isFullCredit;
      this.vendorModalAddUpdateForm.reservationTypes = params.node.data
        .reservationTypes || {
        normal: true,
        fullCredit: false,
        payOnArrival: false,
        partialPayment: false,
      };
      this.vendorModalAddUpdateForm.overSelling = params.node.data.overSelling;
      this.vendorModalAddUpdateForm.isNetPrice = params.node.data.isNetPrice;

      this.vendorModalAddUpdateForm.findeksNote = params.node.data.findeksNote;

      this.vendorModalAddUpdateForm.address = params.node.data.address;
      this.vendorModalAddUpdateForm.phone = params.node.data.phone;
      this.vendorModalAddUpdateForm.inclusives = params.node.data.inclusives;
      this.vendorModalAddUpdateForm.email = params.node.data.email;
      this.vendorModalAddUpdateForm.taxOffice = params.node.data.taxOffice;
      this.vendorModalAddUpdateForm.taxNumber = params.node.data.taxNumber;

      this.vendorModalAddUpdateForm.tracking = params.node.data.tracking;
      this.vendorModalAddUpdateForm.mersisNumber =
        params.node.data.mersisNumber;

      if (params.node.data.contacts) {
        if (params.node.data.contacts.length > 0) {
          this.vendorModalAddUpdateForm.contacts = JSON.parse(
            params.node.data.contacts
          );
        }
      }

      this.vendorModalAddUpdate = true;
    },
  },
};
</script>
<style scoped>
.swal2-container {
  z-index: 100000002 !important;
}
.v-input {
  font-size: 13px !important;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
